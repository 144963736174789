import { Popover } from '@headlessui/react';
import { Link } from '@root/components/ui/links/link/';
import Image from 'next/image';
import React from 'react';

import { ListPanel } from './panels/list-panel';
import { ThreeColumnsPanel } from './panels/three-columns';
import { TwoColumnsPanel } from './panels/two-columns';

import styles from './nav-main.module.scss';

// import IappImg from './iapp-icon-48x48.png';

import EmbargoMarkImg from '@root/images/brand/embargo.mark.192x192.png';

// const TwitterIcon = (props) => (
//     <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
//       <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
//     </svg>
// );

const GithubIcon = props => (
	<svg
		fill='currentColor'
		viewBox='0 0 24 24'
		{...props}>
		<path
			fillRule='evenodd'
			d='M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z'
			clipRule='evenodd'
		/>
	</svg>
);

// const LinkedinIcon = () => (
//     <svg className='h-6 w-6 fill-gray-400' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
//         <path d="M416 32H32C14 32 0 47 0 64v384c0 18 14 32 32 32h384c18 0 32-14 32-32V64c0-17-14-32-32-32zM135 416H69V202h67v214zm-33-243a39 39 0 1 1 0-77 39 39 0 0 1 0 77zm282 243h-66V312c0-25-1-57-35-57s-40 27-40 55v106h-66V202h64v29h1c9-16 30-34 63-34 67 0 79 44 79 102v117z"/>
//     </svg>
// )

// const IappIcon = () => (
//     <Image src={IappImg} className='h-6 w-6' alt=''/>
// );

const navigation = [
	{
		name: 'Privacy Leaders',
		shortName: 'Privacy',
		panel: (
			<ThreeColumnsPanel
				entries={{
					upperDeck: [
						{
							title: 'Get Started',
							entries: [
								{
									name: 'Catalogue',
									href: '/docs/404',
									icon: BookOpenIcon,
								},
								{
									name: 'Annotate',
									href: '/docs/404',
									icon: CursorArrowRippleIcon,
								},
								{
									name: 'Monitor',
									href: '/docs/404',
									icon: ArrowTrendingDownIcon,
								},
								{
									name: 'Restrict',
									href: '/docs/404',
									icon: LockClosedIcon,
								},
							],
						},
						{
							title: 'Guides',
							entries: [
								{
									name: 'Annotating Elements',
									href: '/docs/guides/404',
									icon: CursorArrowRippleIcon,
								},
								{
									name: 'Tagging Resources',
									href: '/docs/guides/404',
									icon: CursorArrowRaysIcon,
								},
								{
									name: 'Monitoring Elements',
									href: '/docs/guides/404',
									icon: ArrowTrendingDownIcon,
								},
								{
									name: 'Restricting Vendors',
									href: '/docs/guides/404',
									icon: LockClosedIcon,
								},
							],
						},
						{
							title: 'Impact',
							entries: [
								{
									name: 'Vendors',
									href: '/industry/vendors',
									icon: BuildingStorefrontIcon,
								},
								{
									name: 'Leak Lookbook',
									href: '/industry/leak-lookbook',
									icon: PhotoIcon,
								},
								{
									name: 'Industry News',
									href: '/industry/news',
									icon: NewspaperIcon,
								},
								{
									name: 'Regulatory Fines',
									href: '/industry/fines/page/1',
									icon: BanknotesIcon,
								},
							],
						},
					],
					blog: {
						name: '',
						href: '',
						posts: [
							{
								title: 'Boost your conversion rate',
								href: '#',
								desc: 'Eget ullamcorper ac ut vulputate fames nec mattis pellentesque elementum. Viverra tempor id mus.',
								imgUrl: 'https://images.unsplash.com/photo-1558478551-1a378f63328e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2849&q=80',
							},
							{
								title: 'Boost your conversion rate',
								href: '#',
								desc: 'Eget ullamcorper ac ut vulputate fames nec mattis pellentesque elementum. Viverra tempor id mus.',
								imgUrl: 'https://images.unsplash.com/photo-1558478551-1a378f63328e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2849&q=80',
							},
						],
					},
				}}
			/>
		),
		href: '#',
	},
	{
		name: 'Developers',
		shortName: 'Devs',
		panel: (
			<ThreeColumnsPanel
				entries={{
					upperDeck: [
						{
							title: 'Guides',
							entries: [
								{
									name: 'Getting Started',
									href: '/docs/sdk/background/getting-started',
									icon: InformationCircleIcon,
								},
								{
									name: 'Code Samples',
									href: '/docs/guides/404',
									icon: CodeBracketIcon,
								},
								{
									name: 'Resource Hooks',
									href: '/docs/guides/404',
									icon: CodeBracketSquareIcon,
								},
								{
									name: 'Vendor Rules',
									href: '/docs/guides/404',
									icon: BriefcaseIcon,
								},
								{
									name: 'Production Rollout',
									href: '/docs/sdk/production/rollout-strategies',
									icon: ChartBarSquareIcon,
								},
							],
						},
						{
							title: 'References',
							entries: [
								// {
								//     name: 'API Spec',
								//     href: '/docs/sdk',
								//     icon: CommandLineIcon,
								// },
								{
									name: 'SDK',
									href: '/docs/sdk',
									icon: CodeBracketSquareIcon,
								},
								{
									name: 'Changelog',
									href: '/changelog',
									icon: SparklesIcon,
								},
								{
									name: 'System Status',
									href: '/status',
									icon: CheckBadgeIcon,
								},
							],
						},
						// {
						//     title: 'Product',
						//     entries: [
						//         {
						//             name: 'Elements',
						//             href: '/404',
						//             icon: CursorArrowRaysIcon,
						//         },
						//         {
						//             name: 'Annotations & Tags',
						//             href: '/404',
						//             icon: CursorArrowRippleIcon,
						//         },
						//         {
						//             name: 'Vendors',
						//             href: '/industry/vendors',
						//             icon: BuildingStorefrontIcon,
						//         },
						//     ]
						// }
					],
					blog: {
						name: '',
						href: '',
						posts: [
							{
								title: 'Boost your conversion rate',
								href: '#',
								desc: 'Eget ullamcorper ac ut vulputate fames nec mattis pellentesque elementum. Viverra tempor id mus.',
								imgUrl: 'https://images.unsplash.com/photo-1558478551-1a378f63328e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2849&q=80',
							},
						],
					},
				}}
			/>
		),
		href: '#',
	},
	{
		name: 'Trust',
		shortName: 'Trust',
		panel: (
			<ThreeColumnsPanel
				entries={{
					upperDeck: [
						{
							title: 'Leadership',
							entries: [
								{
									name: 'Security',
									href: '/docs/trust/security',
									icon: ShieldCheckIcon,
								},
								{
									name: 'Privacy',
									href: '/docs/trust/privacy',
									icon: EyeSlashIcon,
								},
								// {
								//     name: 'Research',
								//     href: '/404',
								//     icon: AcademicCapIcon,
								// },
								{
									name: 'Company',
									href: '/company/about-us',
									icon: GlobeAltIcon,
								},
							],
						},
						{
							title: 'Reliability',
							entries: [
								{
									name: 'System Status',
									href: '/status',
									icon: CheckBadgeIcon,
								},
								{
									name: 'Rollout',
									href: '/docs/trust/reliability/rollout',
									icon: ChartBarSquareIcon,
								},
								{
									name: 'Infrastructure',
									href: '/docs/trust/reliability/infra',
									icon: CloudIcon, // Or Globe icon
								},
							],
						},
						{
							title: 'Alignment',
							entries: [
								{
									name: 'SOC 2',
									href: '/docs/trust/alignment/soc-2',
									icon: DocumentTextIcon,
								},
								{
									name: 'ISO 27001',
									href: '/docs/trust/alignment/iso-27001',
									icon: DocumentTextIcon,
								},
								{
									name: 'PCI DSS',
									href: '/docs/trust/alignment/pci-dss',
									icon: DocumentTextIcon,
								},
							],
						},
					],
					blog: {
						name: '',
						href: '',
						posts: [
							{
								title: 'Boost your conversion rate',
								href: '#',
								desc: 'Eget ullamcorper ac ut vulputate fames nec mattis pellentesque elementum. Viverra tempor id mus.',
								imgUrl: 'https://images.unsplash.com/photo-1558478551-1a378f63328e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2849&q=80',
							},
							{
								title: 'Boost your conversion rate',
								href: '#',
								desc: 'Eget ullamcorper ac ut vulputate fames nec mattis pellentesque elementum. Viverra tempor id mus.',
								imgUrl: 'https://images.unsplash.com/photo-1558478551-1a378f63328e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2849&q=80',
							},
						],
					},
				}}
			/>
		),
		href: '#',
	},
	{
		name: 'Resources',
		shortName: 'Industry',
		panel: (
			<TwoColumnsPanel
				entries={{
					upperDeck: [
						{
							title: 'Support',
							entries: [
								// {
								//     name: 'Knowledge Base',
								//     href: '/404',
								//     icon: AcademicCapIcon,
								// },
								// {
								//     name: 'Community',
								//     href: '/404', // TODO: Redirect to Discord
								//     icon: GlobeAltIcon,
								// },
								{
									name: 'Guides',
									href: '/docs/guides',
									icon: DocumentTextIcon,
								},
								// {
								//     name: 'Webinars',
								//     href: '/404',
								//     icon: ComputerDesktopIcon,
								// },
							],
						},
						{
							title: 'Industry',
							entries: [
								// {
								//     name: 'Ecosystem',
								//     href: '/404',
								//     icon: PuzzlePieceIcon,
								// },
								{
									name: 'Vendors',
									href: '/industry/vendors',
									icon: BuildingStorefrontIcon,
								},
								{
									name: 'Leak Lookbook',
									href: '/industry/leak-lookbook',
									icon: PhotoIcon,
								},
								{
									name: 'Regulatory Fines',
									href: '/industry/fines/page/1',
									icon: BanknotesIcon,
								},
								{
									name: 'Industry News',
									href: '/industry/news',
									icon: NewspaperIcon,
								},
							],
						},
					],
					blog: {
						name: '',
						href: '',
						posts: [
							{
								title: 'Boost your conversion rate',
								href: '#',
								desc: 'Eget ullamcorper ac ut vulputate fames nec mattis pellentesque elementum. Viverra tempor id mus.',
								imgUrl: 'https://images.unsplash.com/photo-1558478551-1a378f63328e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2849&q=80',
							},
							{
								title: 'Boost your conversion rate',
								href: '#',
								desc: 'Eget ullamcorper ac ut vulputate fames nec mattis pellentesque elementum. Viverra tempor id mus.',
								imgUrl: 'https://images.unsplash.com/photo-1558478551-1a378f63328e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2849&q=80',
							},
						],
					},
				}}
			/>
		),
		href: '#',
	},
	{
		name: 'Company',
		shortName: 'Company',
		panel: (
			<ThreeColumnsPanel
				entries={{
					upperDeck: [
						{
							title: 'Embargo',
							entries: [
								{
									name: 'Contact Us',
									href: '/company/contact-us',
									icon: PhoneIcon,
								},
								{
									name: 'About Us',
									href: '/company/about-us',
									icon: UserGroupIcon,
								},
								{
									name: 'Careers',
									href: '/company/careers',
									icon: UserPlusIcon,
								},
								// {
								//     name: 'Become a Partner',
								//     href: '/company/partner',
								//     icon: GlobeAltIcon,
								// },
							],
						},
						{
							title: 'Socials',
							entries: [
								{
									name: 'Blog',
									href: '/blog',
									icon: NewspaperIcon,
								},
								{
									name: 'Industry News',
									href: '/industry/news',
									icon: NewspaperIcon,
								},
								{
									name: 'Github',
									href: 'https://github.com/EmbargoKit',
									icon: GithubIcon,
								},
								// {
								//     name: 'Linkedin',
								//     href: '/404',
								//     icon: LinkedinIcon,
								// },{
								//     name: 'IAPP',
								//     href: '/404',
								//     icon: IappIcon,
								// },
							],
						},
						{
							title: 'Media',
							entries: [
								{
									name: 'Brand Guidelines',
									href: '/company/brand-guidelines',
									icon: DocumentTextIcon,
								},
								{
									name: 'Press Newsroom',
									href: '/company/press',
									icon: NewspaperIcon,
								},
							],
						},
					],
					blog: {
						name: '',
						href: '',
						posts: [
							{
								title: 'Boost your conversion rate',
								href: '#',
								desc: 'Eget ullamcorper ac ut vulputate fames nec mattis pellentesque elementum. Viverra tempor id mus.',
								imgUrl: 'https://images.unsplash.com/photo-1558478551-1a378f63328e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2849&q=80',
							},
						],
					},
				}}
			/>
		),
		href: '#',
	},
	{
		name: 'Pricing',
		shortName: 'Pricing',
		href: '/pricing',
		panel: (
			<ListPanel
				entries={{
					upperDeck: [
						{
							name: 'Pricing',
							href: '/pricing',
							icon: CurrencyDollarIcon,
							desc: 'Scalable pricing tiers broken down by features and benefits.',
						},
						{
							name: 'Startup Program',
							href: '/pricing/startup-program',
							icon: RocketLaunchIcon,
							desc: 'Boost your early startup with discounted pricing.',
						},
						{
							name: 'Enterprise Program',
							href: '/pricing/enterprise-program',
							icon: BuildingOffice2Icon,
							desc: 'Develop our corporate relationship across a longer sales cycle.',
						},
						// {
						//     name: 'Experts Hub',
						//     href: '/industry/experts',
						//     icon: UserGroupIcon,
						//     desc: 'Commit to corporate change with dedicated industry experts.',
						// },
					],
					lowerDeck: [
						// {
						//     name: 'Watch Demo',
						//     href: '/demo',
						//     icon: VideoCameraIcon,
						// },
						{
							name: 'Book A Demo',
							href: '/demo/book-demo',
							icon: CalendarDaysIcon,
						},
						{
							name: 'Contact Sales',
							href: '/company/contact-us', // TODO: This is not sales specific
							icon: PhoneIcon,
						},
						{
							name: 'Trust & Certifications',
							href: '/docs/trust',
							icon: ShieldCheckIcon,
						},
					],
				}}
			/>
		),
	},
];

export const NavMain = () => {
	return (
		<header className='bg-nightBlue relative z-10'>
			<nav
				className='mx-auto max-w-7xl px-0 sm:px-6 lg:px-8'
				aria-label='Top'>
				<div className='flex w-full items-center justify-between border-b border-indigo-500 py-6 lg:border-none'>
					<div className='flex items-center'>
						<Link
							href='/'
							className='hidden lg:inline'>
							<span className='sr-only'>Embargo</span>
							<Image
								className='h-10 w-auto'
								src={EmbargoMarkImg}
								alt=''
							/>
						</Link>

						<div className='mx-4 flex'>
							{navigation.map(item => (
								<Entry
									key={item.name}
									{...item}
								/>
							))}
						</div>
					</div>

					<div className='mr-1 ml-1 space-x-4'>
						<span className='inline-flex items-center rounded-md bg-indigo-900 px-2.5 py-0.5 text-sm font-medium text-indigo-400'>
							Exclusive Beta
						</span>
						{/* <Link
                            href="https://app.embargokit.com/auth/sign-in"
                            className="inline-block rounded-md border border-transparent bg-indigo-500 text-white 
                            hover:text-white hover:bg-opacity-90 font-normal text-sm py-2 px-2 sm:py-2 sm:px-4 sm:text-base sm:font-medium"
                        >
                            Sign in
                        </Link>
                        <Link
                            href="https://app.embargokit.com/auth/sign-up"
                            className="hidden lg:inline inline-block rounded-md border border-transparent bg-white py-2 px-4 text-base font-medium text-indigo-600 hover:bg-indigo-50"
                        >
                            <span>Sign up</span>
                        </Link> */}
					</div>
				</div>
			</nav>
		</header>
	);
};

import { ChevronDownIcon } from '@heroicons/react/20/solid';
import {
	ArrowTrendingDownIcon,
	BanknotesIcon,
	BookOpenIcon,
	BriefcaseIcon,
	BuildingOffice2Icon,
	BuildingStorefrontIcon,
	CalendarDaysIcon,
	ChartBarSquareIcon,
	CheckBadgeIcon,
	CloudIcon,
	CodeBracketIcon,
	CodeBracketSquareIcon,
	CurrencyDollarIcon,
	CursorArrowRaysIcon,
	CursorArrowRippleIcon,
	DocumentTextIcon,
	EyeSlashIcon,
	GlobeAltIcon,
	InformationCircleIcon,
	LockClosedIcon,
	NewspaperIcon,
	PhoneIcon,
	PhotoIcon,
	RocketLaunchIcon,
	ShieldCheckIcon,
	SparklesIcon,
	UserGroupIcon,
	UserPlusIcon,
} from '@heroicons/react/24/outline';

function classNames(...classes) {
	return classes.filter(Boolean).join(' ');
}

export type EntryProps = {
	name: string;
	panel: React.ReactNode;
	compact: boolean;
};

export const Entry = ({ name, shortName, panel: Panel }) => {
	return (
		<Popover className='relative z-0'>
			{({ open }) => (
				<>
					<div className='relative z-10 mr-2'>
						{/* <div className="mx-auto flex max-w-7xl px-4 py-6 sm:px-6 lg:px-8"> */}
						<Popover.Button
							className={classNames(
								open ? 'text-gray-400' : 'text-gray-200',
								'group inline-flex items-center sm:px-1 rounded-md text-base font-medium border-0 hover:text-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-0 ',
							)}>
							<span className='hidden md:inline'>{name}</span>
							<span className='inline md:hidden'>
								{shortName}
							</span>
							<ChevronDownIcon
								className={classNames(
									open ? 'text-gray-400' : 'text-gray-200',
									'hidden lg:inline h-4 w-4 group-hover:text-gray-400 mt-1',
								)}
								aria-hidden='true'
							/>
							&nbsp;
						</Popover.Button>
						{/* </div> */}
					</div>

					{/* <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 -translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 -translate-y-1"
          > */}

					{/* overflow-y: scroll;
            max-height: calc(100vh - 6rem); */}
					<Popover.Panel className={styles.panel}>
						{Panel}
					</Popover.Panel>
					{/* </Transition> */}
				</>
			)}
		</Popover>
	);
};
