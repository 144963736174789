type Entry = {
	name: string;
	href: string;
	icon: any;
};

type EntryWithDesc = Entry & {
	desc: string;
};

export type PanelEntriesType = {
	upperDeck: EntryWithDesc[];
	lowerDeck: Entry[];
};

export type ListPanelProps = {
	entries: PanelEntriesType;
};

export const ListPanel = ({ entries }: ListPanelProps) => {
	return (
		<>
			<div className='bg-white'>
				<div className='mx-auto grid max-w-7xl gap-y-6 px-4 py-6 sm:grid-cols-2 sm:gap-8 sm:px-6 sm:py-8 lg:grid-cols-4 lg:px-8 lg:py-12 xl:py-16'>
					{entries.upperDeck.map(item => (
						<a
							key={item.name}
							href={item.href}
							className='-m-3 flex flex-col justify-between rounded-lg p-3 transition duration-150 ease-in-out hover:bg-gray-50'>
							<div className='flex md:h-full lg:flex-col'>
								<div className='flex-shrink-0'>
									<div className='inline-flex h-10 w-10 items-center justify-center rounded-md bg-indigo-500 text-white sm:h-12 sm:w-12'>
										<item.icon
											className='h-6 w-6'
											aria-hidden='true'
										/>
									</div>
								</div>
								<div className='ml-4 md:flex md:flex-1 md:flex-col md:justify-between lg:ml-0 lg:mt-4'>
									<div>
										<p className='text-base font-medium text-gray-900'>
											{item.name}
										</p>
										<p className='mt-1 text-sm text-gray-500'>
											{item.desc}
										</p>
									</div>
									<p className='mt-2 text-sm font-medium text-indigo-600 lg:mt-4'>
										Learn more
										<span aria-hidden='true'> &rarr;</span>
									</p>
								</div>
							</div>
						</a>
					))}
				</div>
			</div>
			<div className='bg-gray-50'>
				<div className='mx-auto max-w-7xl space-y-6 px-4 py-5 sm:flex sm:space-y-0 sm:space-x-10 sm:px-6 lg:px-8'>
					{entries.lowerDeck.map(item => (
						<div
							key={item.name}
							className='flow-root'>
							<a
								href={item.href}
								className='-m-3 flex items-center rounded-md p-3 text-base font-medium text-gray-900 transition duration-150 ease-in-out hover:bg-gray-100'>
								<item.icon
									className='h-6 w-6 flex-shrink-0 text-gray-400'
									aria-hidden='true'
								/>
								<span className='ml-3'>{item.name}</span>
							</a>
						</div>
					))}
				</div>
			</div>
		</>
	);
};
