import { Link } from '@root/components/ui/links/link/';

export type PanelEntriesType = {
	upperDeck: {
		title: string;
		entries: {
			name: string;
			href: string;
			icon: any;
		}[];
	}[];
	blog: {
		name: string;
		href: string;
		posts: {
			title: string;
			desc: string;
			href: string;
			imgUrl: string;
		}[];
	};
};

export type TwoColumnsPanelProps = {
	entries: PanelEntriesType;
};

export const TwoColumnsPanel = ({ entries }: TwoColumnsPanelProps) => {
	return (
		<>
			<div
				className='absolute inset-0 flex'
				aria-hidden='true'>
				<div className='w-1/2 bg-white' />
				<div className='w-1/2 bg-gray-50' />
			</div>
			<div className='relative mx-auto grid max-w-7xl grid-cols-1 lg:grid-cols-2'>
				<nav
					className='grid gap-y-10 bg-white px-4 py-8 grid-cols-2 sm:gap-x-8 sm:py-12 sm:px-6 lg:px-8 xl:pr-12'
					// aria-labelledby="solutions-heading"
				>
					<h2
						// id="solutions-heading"
						className='sr-only'>
						Menu
					</h2>
					{entries.upperDeck.map(columnEntry => (
						<div key={columnEntry.title}>
							<h3 className='text-base font-medium text-gray-500'>
								{columnEntry.title}
							</h3>
							<ul
								role='list'
								className='mt-5 space-y-6'>
								{columnEntry.entries.map(item => (
									<li
										key={item.name}
										className='flow-root'>
										<Link
											href={item.href}
											className='-m-3 flex items-center rounded-md p-3 text-base font-medium text-gray-900 transition duration-150 ease-in-out hover:bg-gray-50'>
											<item.icon
												className='h-6 w-6 flex-shrink-0 text-gray-400'
												aria-hidden='true'
											/>
											<span className='ml-4'>
												{item.name}
											</span>
										</Link>
									</li>
								))}
							</ul>
						</div>
					))}
				</nav>
				{/* <div className="bg-gray-50 px-4 py-8 sm:py-8 sm:px-6 lg:px-8 xl:pl-12">
            <div>
              <h3 className="text-base font-medium text-gray-500">
                From the blog
              </h3>
              <ul role="list" className="mt-4 space-y-4">
                {entries.blog.posts.map((post,i) => (
                  <li key={i} className="flow-root">
                    <Link
                      href={post.href}
                      className="-m-3 flex rounded-lg p-3 transition duration-150 ease-in-out hover:bg-gray-100"
                    >
                      <div className="hidden flex-shrink-0 sm:block">
                        <img
                          className="h-20 w-32 rounded-md object-cover"
                          src={post.imgUrl}
                          alt=""
                        />
                      </div>
                      <div className="min-w-0 flex-1 sm:ml-6">
                        <h4 className="truncate text-base font-medium text-gray-900">
                          {post.title}
                        </h4>
                        <p className="mt-1 mb-0 text-sm text-gray-500">
                          {post.desc}
                        </p>
                      </div>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            <div className="mt-4 text-sm font-medium">
              <Link
                href="/blog"
                className="text-indigo-600 transition duration-150 ease-in-out hover:text-indigo-500"
              >
                View all posts
                <span aria-hidden="true"> &rarr;</span>
              </Link>
            </div>
          </div> */}
			</div>
		</>
	);
};
