import NextLink, { LinkProps as NextLinkProps } from 'next/link';
import { PropsWithChildren } from 'react';

const ORIGIN = 'https://embargokit.com';

export type LinkProps = PropsWithChildren<
	{
		href: string;
		className?: string;
		rel?: string;
		target?: string;
		as?: string;
		scroll?: boolean;
		shaddow?: boolean;
	} & NextLinkProps
>;
export const Link = (props: LinkProps) => {
	const { children, ...otherProps } = props;

	// Assume no base tag used
	// Cannot use location for static builds SSR without further work

	let isExternal = false;
	if (props.href?.startsWith('https://') || props.href?.startsWith('http://')) {
		const absoluteUrl = new URL(props.href, ORIGIN);
		isExternal = absoluteUrl.origin !== ORIGIN;
	}

	const passThrough = {
		scroll: true,
		shallow: false,

		...otherProps,

		href: props.href,
		rel: isExternal ? 'noopener nofollow noreferrer' : 'noopener',
		target: isExternal ? '_blank' : undefined,
	};
	return <NextLink {...passThrough}>{children}</NextLink>;
};
